import { createContext, Dispatch, useContext, useReducer } from "react";

const initialState: ClientState = {
  entry: null,
  viewingGroup: null,
};

const ClientContext = createContext(initialState);
const ClientDispatchContext = createContext(null);

function clientStateReducer(state: ClientState, action: ClientAction): ClientState {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_ROSTER: {
      return { ...state, entry: action.entry };
    }
    case ACTIONS.SET_CURRENT_VIEWING_GROUP: {
      return { ...state, viewingGroup: action.viewingGroup };
    }
    default: {
      throw new Error(`Unhandled action in clientStateReducer: ${action}`);
    }
  }
}

export function ClientContextProvider({ children }) {
  const [state, dispatch] = useReducer(clientStateReducer, initialState);

  return (
    <ClientContext.Provider value={state}>
      <ClientDispatchContext.Provider value={dispatch}>{children}</ClientDispatchContext.Provider>
    </ClientContext.Provider>
  );
}

function useClientState() {
  const context = useContext<ClientState>(ClientContext);
  if (context === undefined) {
    throw new Error("useClientState must be used within a ClientContext Provider");
  }
  return context;
}

function useClientDispatch() {
  const context = useContext<Dispatch<ClientAction>>(ClientDispatchContext);
  if (context === undefined) {
    throw new Error("useClientDispatch must be used within a ClientDispatchContext Provider");
  }
  return context;
}

export default function useClientContext(): [ClientState, Dispatch<ClientAction>] {
  return [useClientState(), useClientDispatch()];
}

type ClientAction = SetCurrentRoster | SetViewingGroup;

interface SetCurrentRoster {
  type: ACTIONS.SET_CURRENT_ROSTER;
  entry: RosterEntry;
}

interface SetViewingGroup {
  type: ACTIONS.SET_CURRENT_VIEWING_GROUP;
  viewingGroup: ClientState["viewingGroup"];
}

type ClientState = {
  entry: RosterEntry | null;
  viewingGroup: Group | null;
};

export interface Pick {
  slot: string;
  slot_id?: number;
  player_id: number;
  points: number;
  round_id?: number;
  has_started?: boolean;
}

export class RosterEntry {
  entry_id?: number;
  name?: string;
  rank?: number | null;
  points?: number | null;
  group_memberships?: Group[];
  picks?: Pick[];
  round_1_pts?: string | null;
  round_2_pts?: string | null;
  round_3_pts?: string | null;
  round_4_pts?: string | null;
  tiebreaker_water_balls?: number;
  tiebreaker: number | null;
  has_made_first_five?: boolean;
  constructor() {
    this.entry_id = undefined;
    this.name = "";
    this.rank = null;
    this.points = null;
  }
}

export interface Group {
  creator_id?: number | null;
  entry_count?: number | null;
  group_id: number;
  group_rank?: number;
  group_name?: string | null;
  name?: string | null;
  description?: string | null;
  is_private?: boolean | null;
  join_token?: string | null;
  group_memberships?: Group[];
}

export interface RosterPick {
  entry_id: number;
}

enum ACTIONS {
  SET_CURRENT_ROSTER = "SET_CURRENT_ROSTER",
  SET_CURRENT_VIEWING_GROUP = "SET_CURRENT_VIEWING_GROUP",
}

export const setCurrentRoster = (entry: ClientState["entry"]): SetCurrentRoster => {
  return { type: ACTIONS.SET_CURRENT_ROSTER, entry };
};

export const setCurrentViewingGroup = (group: ClientState["viewingGroup"]): SetViewingGroup => {
  return { type: ACTIONS.SET_CURRENT_VIEWING_GROUP, viewingGroup: group };
};
