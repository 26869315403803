import { useRouter } from "next/router";
import { useState } from "react";
import bridgeEvents from "../utils/bridgeEvents";

export const useServeBundle = () => {
  const router = useRouter();
  const [savedScrollPosition, setSavedScrollPosition] = useState(0);

  const getWindowHash = () => {
    window.addEventListener(
      "message",
      (event) => {
        if (
          event.origin === "https://pgachampionship.uat.pga.golf.psdops.com" ||
          event.origin === "https://www.pgachampionship.com" ||
          event.origin === "https://www.kpmgwomenspgachampionship.com" ||
          event.origin === "https://kpmg.uat.pga.golf.psdops.com"
        ) {
          const eventData = JSON.parse(event.data);
          switch (eventData.name) {
            case "windowHash":
              if (eventData.value) {
                const splitUrl = eventData.value.split("/");
                const groupId = splitUrl[2];
                const groupJoinToken = splitUrl[splitUrl.length - 1];
                router.push(`/groups/${groupId}?groupJoinToken=${groupJoinToken}`);
              }
              break;
            default:
              console.warn(`${eventData.name} - Not implemented`);
          }
        }
      },
      false,
    );

    bridgeEvents.getWindowHash();
  };

  const getAndSaveWindowScrollTop = () => {
    window.addEventListener(
      "message",
      (event) => {
        if (
          event.origin === "https://pgachampionship.uat.pga.golf.psdops.com" ||
          event.origin === "https://www.pgachampionship.com" ||
          event.origin === "https://www.kpmgwomenspgachampionship.com" ||
          event.origin === "https://kpmg.uat.pga.golf.psdops.com"
        ) {
          const eventData = JSON.parse(event.data);
          switch (eventData.name) {
            case "getWindowScrollTop":
              if (eventData.value) setSavedScrollPosition(eventData.value);
              break;
            default:
              console.warn(`${eventData.name} - Not implemented`);
          }
        }
      },
      false,
    );

    bridgeEvents.getWindowScrollTop();
  };

  return { getWindowHash, getAndSaveWindowScrollTop, savedScrollPosition };
};
