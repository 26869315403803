import { createGlobalStyle } from "styled-components";
import { FONTS, palette } from "./styleUtils";

export const GlobalStyles = createGlobalStyle`
*, :after, :before {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  margin: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}

html {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
}

body {
  font-family: inherit;
  color: ${palette.supportingColor1};
  line-height: 1.5;
  font-size: 16px;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  margin: 0 auto;
}

ul, li {
  margin: 0;
  padding: 0;
}

#root *, #root *:after, #root *:before {
  box-sizing: border-box;
}

#root *:after, #root *:before {
  pointer-events: none;
}

* {
  -webkit-touch-callout: none;
}

p, h1, h2, h3, h4, h5, pre, code, .u-selectable {
  user-select: text;
  -webkit-user-select: text;
  -webkit-touch-callout: default;
  white-space: normal;
}

a {
  color: #0077cb;
}

h1, h2 {
  font-family: ${FONTS.SERIF};
  font-weight: 600;
}

 h3, h4, h5, h6 {
  font-family: ${FONTS.ROBOTO};
}

input,
textarea,
select {
  user-select: text;
  -webkit-touch-callout: default;
}

input::selection,
textarea::selection {
  background-color: rgba(0, 0, 0, 0.4);
}

body p {
  margin-bottom: 0;
  padding: 0;
  line-height: 1.5em;
  font-size: inherit;
  text-rendering: optimizeLegibility;
}

button, body a {
  cursor: pointer;
  text-transform: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  font: inherit;
  outline: none;
}

button:active, a:active {
  outline: none;
}

body button, body input, body optgroup, body select, body textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button > *, a > * {
  pointer-events: none;
}

*:disabled {
  pointer-events: none;
  cursor: default;
}
`;
