import { AnimatePresence, motion } from "framer-motion";
import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import bridgeEvents from "../../utils/bridgeEvents";
import styled from "styled-components";
import { breakpoints, palette } from "../../styles/styleUtils";
import { useServeBundle } from "../../hooks/useServeBundle";

const AnimatedModalBackdrop = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  opacity: 0.9;
  background-color: ${palette.neutral1};
`;

const ModalContainer = styled.div`
  z-index: 100;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnimatedModalContainer = styled(motion.div)`
  position: fixed;
  z-index: 500;
  box-sizing: border-box;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.neutral1};
  scroll-margin-top: 160px;

  @media (min-width: ${breakpoints.laptop}) {
    align-self: flex-start;
    margin-top: 50px;
  }

  @media (min-width: ${breakpoints.tablet}) and (min-height: 400px) {
    position: relative;
    min-width: 900px;
    max-width: 1024px;
    min-height: 600px;
    max-height: 92%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
  }
`;

const ModalContent = styled.div`
  height: 100%;
  overflow: scroll;

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`;
interface ModalContextType {
  showModal: Dispatch<SetStateAction<JSX.Element>>;
  dismissModal: () => void;
  modalContent: JSX.Element | null;
}

const ModalContext = createContext({} as ModalContextType);

export const useModal = () => useContext(ModalContext);

const ModalProvider: FunctionComponent = ({ children }) => {
  const [modalContent, setModalContent] = useState<ModalContextType["modalContent"]>();
  const modalContentsRef = useRef(null);
  const animatedModalContainerRef = useRef(null);
  const { savedScrollPosition, getAndSaveWindowScrollTop } = useServeBundle();

  const showModal: Dispatch<SetStateAction<ModalContextType["modalContent"]>> = (content) => {
    setModalContent(content);
    document.body.classList.toggle("modal-open", !!content);
  };

  const dismissModal = () => {
    setModalContent(null);
    showModal(null);
  };

  useEffect(() => {
    function updateIframeHeight(content) {
      if (content) {
        const isIframe = window?.location !== window?.parent.location;
        if (isIframe) {
          // 767 is the pixel width when modals go fullscreen
          const fullscreenModalWidth = 767;
          const isMobile = document.body.offsetWidth <= fullscreenModalWidth;
          if (isMobile) {
            const modalContentsHeight = modalContentsRef.current.clientHeight;
            setTimeout(() => {
              document.body.style["height"] = `${modalContentsHeight}px`;
            }, 400);
          }
          getAndSaveWindowScrollTop();
          bridgeEvents.scrollElementIntoView(animatedModalContainerRef);
        }
      } else {
        document.body.style.removeProperty("height");
        bridgeEvents.setWindowScrollTop(savedScrollPosition);
      }
    }
    updateIframeHeight(modalContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContent]);

  return (
    <ModalContext.Provider
      value={{
        showModal,
        dismissModal,
        modalContent,
      }}
    >
      <AnimatePresence>
        {modalContent && (
          <ModalContainer>
            <AnimatedModalContainer
              key="modal"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeOut", duration: 0.3 }}
              ref={animatedModalContainerRef}
            >
              <ModalContent>
                <div ref={modalContentsRef}>{modalContent}</div>
              </ModalContent>
            </AnimatedModalContainer>
            <AnimatedModalBackdrop
              onClick={dismissModal}
              key="modal-backdrop"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.9 }}
              exit={{ opacity: 0 }}
              transition={{ type: "spring", duration: 0.4 }}
            />
          </ModalContainer>
        )}
      </AnimatePresence>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
