import Script from "next/script";
import React, { useCallback, useRef, useEffect } from "react";
import settings from "../settings";

export const AnalyticsScript = React.memo(() => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
    }
  });
  return (
    <>
      <Script async src={`https://www.googletagmanager.com/gtag/js?id=${settings.GOOGLE_TAG}`} />
      <Script>
        {" "}
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${settings.GOOGLE_TAG}');`}
      </Script>
    </>
  );
});

/**
 * Hook to trigger analytics events & utilize the analytics engine.
 */
export default function useAnalytics() {
  // const queue: AnalyticsData[] = useMemo(() => [], []);
  const timeoutId = useRef<NodeJS.Timeout | number | null>(null);
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
  }

  const isWomens = settings.PRODUCT_VERSION === "womens";

  const fireEvent = useCallback((eventData: AnalyticsData) => {
    if (!timeoutId.current && typeof window !== "undefined") {
      if (window.gtag) window.gtag("event", eventData.event, { params: eventData.params });
    }
  }, []);

  return { fireEvent, isWomens };
}

declare global {
  interface Window {
    googletag: any;
    dataLayer: any[];
    gtag: (arg1?: any, arg2?: any, arg3?: any) => void;
  }
}

interface AnalyticsData {
  event?: string;
  params?: any;
}
