import Cookies from "js-cookie";
import { User, RegistrationData, LoginData, ResetPasswordInfo, UpdateData } from "../constants/interfaces/auth";
import { useQuery } from "react-query";
import { QUERIES } from "../constants/queries";
import useClientContext, { setCurrentRoster, RosterEntry } from "./useClientContext";
import settings from "../settings";
import bridgeEvents from "../utils/bridgeEvents";

export const API_COOKIE_NAME = settings.API_COOKIE_NAME;
const TokenHeader = "x-sti-set-authorization";

export const registerUser = async (registrationData: RegistrationData): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    return fetch(`${settings.API_URL}/api/v1/pgaoaroster/sessions/register.json`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(registrationData),
    })
      .then(async (res) => {
        const userToken = res.headers.get(TokenHeader);
        const response = await res.json();

        if (res.status === 200) {
          if (window) {
            Cookies.set(API_COOKIE_NAME, userToken, { path: "/", expires: 365, secure: true, sameSite: "none" });
            bridgeEvents.setToken(userToken);
          }
          return resolve(response);
        } else {
          if (response.errors.email) reject(`Email ${response.errors.email[0]}`);
          else if (response.errors.password) reject(response.errors.password[0]);
          else reject("Something went wrong in registering please try again.");
        }
      })
      .catch(() => {
        reject("Something went wrong in registering please try again.");
      });
  });
};

export const updateUser = async (registrationData: UpdateData): Promise<User | null> => {
  let userToken;
  const cookieToken = Cookies.get(API_COOKIE_NAME);

  if (!cookieToken) {
    return null;
  } else {
    userToken = cookieToken;
  }

  return new Promise((resolve, reject) => {
    return fetch(`${settings.API_URL}/api/v1/pgaoaroster/sessions.json`, {
      method: "PUT",
      headers: {
        "X-Sti-Auth-Token": "Bearer " + userToken,
        "Content-type": "application/json",
      },
      body: JSON.stringify({ user: registrationData }),
    })
      .then(async (res) => {

        const response = await res.json();

        if (res.status === 200) {
          return resolve(response);
        } else {
          if (response.errors.email) reject(`Email ${response.errors.email[0]}`);
          else if (response.errors.password) reject(response.errors.password[0]);
          else reject("Something went wrong in registering please try again.");
        }
      })
      .catch(() => {
        reject("Something went wrong in registering please try again.");
      });
  });
};

export const loginUser = async (loginData: LoginData): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    return fetch(`${settings.API_URL}/api/v1/pgaoaroster/sessions.json`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then(async (res) => {
        const userToken = res.headers.get(TokenHeader);
        const response = await res.json();
        if (res.status === 200 && response.user_id) {
          if (window) {
            Cookies.set(API_COOKIE_NAME, userToken, { path: "/", expires: 365, secure: true, sameSite: "none" });
            bridgeEvents.setToken(userToken);
          }
          return resolve(response);
        } else {
          reject(response.errors.messages[0]);
        }
      })
      .catch(() => {
        reject("Something went wrong loggin in please try again.");
      });
  });
};

export const forgotUserPassword = async (emailAddress: string): Promise<boolean> => {
  console.warn(emailAddress);
  return new Promise((resolve, reject) => {
    return fetch(`${settings.API_URL}/api/v1/pgaoaroster/sessions/password-reset/start.json`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ email: emailAddress }),
    })
      .then(async (res) => {
        if (res.status < 400) {
          resolve(true);
        } else {
          reject("Something went wrong while resetting your password. Please try again.");
        }
      })
      .catch(() => {
        reject("Something went wrong while resetting your password. Please try again.");
      });
  });
};

export const validateToken = async (token: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    return fetch(`${settings.API_URL}/api/v1/pgaoaroster/sessions/password-reset/validate-token.json `, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ token: token }),
    })
      .then(async (res) => {
        if (res.status < 400) {
          resolve(true);
        } else {
          reject("That link is not valid");
        }
      })
      .catch(() => {
        reject("Something went wrong while resetting your password. Please try again.");
      });
  });
};

export const resetUserPassword = async (resetPasswordInfo: ResetPasswordInfo): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    return fetch(`${settings.API_URL}/api/v1/pgaoaroster/sessions/password-reset/reset.json `, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(resetPasswordInfo),
    }).then(async (res) => {
      if (res.status < 400) {
        resolve(true);
      }
      if (res.status > 400) {
        reject(`Sorry something went wrong resetting your password. Please try again.`);
      }
    });
  });
};

export const getSession = async (token?): Promise<User | null> => {
  let userToken;
  const cookieToken = Cookies.get(API_COOKIE_NAME);
  if (!cookieToken && !token) {
    return null;
  } else if (token) {
    userToken = token;
  } else {
    userToken = cookieToken;
  }

  return new Promise((resolve, reject) => {
    if (!userToken) {
      return reject(new Error("TOKEN_REQUIRED"));
    }
    return fetch(`${settings.API_URL}/api/v1/pgaoaroster/sessions.json`, {
      method: "get",
      headers: {
        "X-Sti-Auth-Token": "Bearer " + userToken,
        "content-type": "application/json; charset=utf-8",
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (res.status === 200) {
          return resolve(response);
        }
        if (res.status >= 400) {
          Cookies.remove(API_COOKIE_NAME);
          return resolve(null);
        } else {
          reject("Sorry, something went wrong");
        }
      })
      .catch((e) => {
        try {
          if (e.toString().includes("Failed to fetch")) {
            reject("Network Error");
          }
        } catch {
          return reject("Sorry, something went wrong");
        }

        return reject("Sorry, something went wrong");
      });
  });
};

export const createEntry = async (data, token): Promise<RosterEntry | null> => {
  let userToken;
  const cookieToken = Cookies.get(API_COOKIE_NAME);
  if (!cookieToken && !token) {
    return null;
  } else if (token) {
    userToken = token;
  } else {
    userToken = cookieToken;
  }

  return new Promise((resolve, reject) => {
    if (!userToken) {
      return reject(new Error("TOKEN_REQUIRED"));
    }
    return fetch(`${settings.API_URL}/api/v1/pgaoaroster/entries.json`, {
      method: "POST",
      headers: {
        "X-Sti-Auth-Token": "Bearer " + userToken,
        "content-type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const response = await res.json();
        if (res.status === 200) {
          return resolve(response);
        } else {
          if (response.errors.name) reject(`Name ${response.errors.name[0]}`);
        }
      })
      .catch((e) => {
        try {
          if (e.toString().includes("Failed to fetch")) {
            reject("Network Error");
          }
        } catch {
          return reject("Sorry, something went wrong");
        }

        return reject("Sorry, something went wrong");
      });
  });
};
export const loadUserFromCookies = async () => {
  const token = Cookies.get(API_COOKIE_NAME);
  if (token) {
    const user = await getSession();
    if (user) {
      return user;
    }
  } else if (typeof window !== undefined) {
    // No token found. Need to return a different value here
  }
  return;
};

export const getTokenServerSide = (req): string | undefined => {
  const cookie = req ? req.headers.cookie : null;
  // This cuts the cookie down to the token
  const token = cookie?.split(`${settings.API_COOKIE_NAME}=`)[1]?.split(";")[0];
  if (token) {
    return token;
  } else {
    return undefined;
  }
};

export const serverSideAuthCheck = async (req, res): Promise<User | void | null> => {
  const token = getTokenServerSide(req);
  if (token) {
    const userRes = await getSession()
      .then((res) => res)
      .catch((err) => {
        console.warn(err);
        res.end();
      });
    // TODO error handling here!
    return userRes;
  } else {
    return null;
  }
};

export const useSessionQuery = (enabled = true) => {
  const [, dispatch] = useClientContext();
  return useQuery(QUERIES.SESSION, () => getSession(), {
    staleTime: 1000 * 60 * 2,
    enabled: enabled,
    refetchInterval: 1000 * 60 * 15,
    onSuccess: (data) => {
      dispatch(setCurrentRoster(data.pgaoaroster_entries[0]));
    },
  });
};
