import { MutableRefObject } from "react";

const bridgeEvents = {
  resizeFrame: (height) => {
    window.parent.postMessage(JSON.stringify({ name: "iframeHeight", value: height }), "*");
  },

  setToken: (token) => {
    window.parent.postMessage(JSON.stringify({ name: "setToken", value: token }), "*");
  },

  getToken: () => {
    window.parent.postMessage(JSON.stringify({ name: "getToken" }), "*");
  },

  removeToken: () => window.parent.postMessage(JSON.stringify({ name: "removeToken" }), "*"),

  getWindowHash: () => window.parent.postMessage(JSON.stringify({ name: "getWindowHash" }), "*"),

  getWindowScrollTop: () => window.parent.postMessage(JSON.stringify({ name: "getWindowScrollTop" }), "*"),

  setWindowScrollTop: (scrollY: number, disableSmooth?: false) =>
    window.parent.postMessage(JSON.stringify({ name: "setWindowScrollTop", value: { scrollY, disableSmooth } }), "*"),

  scrollElementIntoView: (element: MutableRefObject<any>, disableSmooth?: false) => {
    const elementPixelsFromTop = element.current.getBoundingClientRect().top;
    window.parent.postMessage(
      JSON.stringify({
        name: "scrollElementIntoView",
        value: { elementPixelsFromTop, disableSmooth },
      }),
      "*",
    );
  },
};

export default bridgeEvents;
