import React, { useEffect } from "react";
import styled from "styled-components";
import RegionProvider, { Region } from "./RegionProvider";
import { breakpoints } from "src/styles/styleUtils";
import bridgeEvents from "../utils/bridgeEvents";
import ModalProvider from "./Modal";

declare global {
  interface Window {
    disableHeightAdjustment: any;
  }
}

const StyledMain = styled.main`
  margin: 0 auto;
  min-height: 750px;
`;

export const GameplayContainer = styled.div`
  padding-bottom: 30px;
`;

export const GameplayPadding = styled.div`
  padding-top: 30px !important;
  padding-bottom: 20px !important;
  max-width: 1200px;
  min-width: 320px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.large}) {
    padding: 0 50px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0 30px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 15px;
  }
`;

const PageLayout: React.FunctionComponent = ({ children }) => {
  useEffect(() => {
    const sendCurrentHeight = () => {
      if (window.disableHeightAdjustment) {
        return;
      }

      const offsetHeight = document.body.offsetHeight;

      const isIframe = window?.location !== window?.parent.location;
      if (isIframe) document.body.style["overflow"] = "hidden";
      bridgeEvents.resizeFrame(offsetHeight);
    };

    window.addEventListener("load", sendCurrentHeight);
    setInterval(sendCurrentHeight, 1000);
  }, []);

  return (
    <RegionProvider defaultRegion={Region.UnitedStates}>
      <ModalProvider>
        <StyledMain>{children}</StyledMain>
      </ModalProvider>
    </RegionProvider>
  );
};

export default PageLayout;
