import React, { createContext, useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import {
  User,
  ISessionContext,
  RegistrationData,
  LoginData,
  EntryData,
  UpdateData,
} from "../constants/interfaces/auth";
import { loginUser, registerUser, createEntry, getSession, updateUser } from "./auth";
import settings from "../settings";
import useClientContext, { RosterEntry, setCurrentRoster } from "./useClientContext";
import { useQueryClient } from "react-query";
import { QUERIES } from "src/constants/queries";
import bridgeEvents from "../utils/bridgeEvents";

const SessionContext = createContext({} as ISessionContext);

export const SessionProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [backendUnavailable, setBackendUnavailable] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [, dispatch] = useClientContext();
  const [token, setToken] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    setIsLoading(true);
    setUser(null);

    const loadUser = async () => {
      const user = await getSession(token).catch((e) => {
        if (e === "Network Error") {
          setBackendUnavailable(true);
        }
      });
      if (user) {
        queryClient.setQueryData<User | null>(QUERIES.SESSION, user);
        setUser(user);
        if (user?.pgaoaroster_entries !== null) dispatch(setCurrentRoster(user.pgaoaroster_entries[0]));
      }
    };
    loadUser().then(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const logout = (): void => {
    const date = new Date();

    Cookies.set(settings.API_COOKIE_NAME, "", {
      path: "/",
      expires: date,
      secure: true,
      sameSite: "none",
    });
    Cookies.remove(settings.API_COOKIE_NAME, {
      path: "/",
      domain: settings.APP_URL,
    });
    bridgeEvents.removeToken();
    setUser(null);
    dispatch(setCurrentRoster(null));
  };

  const registerSubmit = (data: RegistrationData): Promise<User | null> => {
    return new Promise((resolve, reject) => {
      registerUser(data)
        .then((res) => {
          setUser(res);
          setCurrentRoster(null);
          bridgeEvents.getToken();
          resolve(res);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  };

  const loginSubmit = (data: LoginData): Promise<User | null> => {
    return new Promise((resolve, reject) => {
      loginUser(data)
        .then((res) => {
          setUser(res);
          if (res.pgaoaroster_entries !== null) dispatch(setCurrentRoster(res.pgaoaroster_entries[0]));
          bridgeEvents.getToken();
          resolve(res);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  };

  const updateSubmit = (data: UpdateData): Promise<User | null> => {
    return new Promise((resolve, reject) => {
      updateUser(data)
        .then((res) => {
          setUser({ ...user, first_name: res.first_name, email: res.email, last_name: res.email });
          resolve(res);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  };

  const entrySubmit = (data: EntryData, token): Promise<RosterEntry | null> => {
    return new Promise((resolve, reject) => {
      createEntry(data, token)
        .then((res) => {
          resolve(res);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  };

  const saveToken = (token) => {
    setToken(token);
  };

  return (
    <SessionContext.Provider
      value={{
        user,
        isAuth: !!user,
        logout,
        loginSubmit,
        updateSubmit,
        registerSubmit,
        entrySubmit,
        saveToken,
        isLoading,
        backendUnavailable,
        token: token,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
