import React, { FunctionComponent, useContext, useState } from "react";
import { ThemeProvider } from "styled-components";
import { EuropeanTheme, UnitedStatesTheme } from "../styles/styleUtils";

export enum Region {
  UnitedStates,
  Europe,
}

const THEMES_BY_REGION = {
  [Region.UnitedStates]: UnitedStatesTheme,
  [Region.Europe]: EuropeanTheme,
};

interface SetRegionFunction extends Function {
  (region: Region): void;
}
interface SetCountryCodeFunction extends Function {
  (countryCode: string): void;
}

interface RegionContextProps {
  region: Region;
  countryCode: string;
  setRegion: SetRegionFunction;
  setCountryCode: SetCountryCodeFunction
}

export const RegionContext = React.createContext<RegionContextProps>(null);

interface RegionProviderPropTypes {
  defaultRegion?: Region;
}

const RegionProvider: FunctionComponent<RegionProviderPropTypes> = ({ defaultRegion, children }) => {
  const [region, setRegion] = useState(defaultRegion);
  const [countryCode, setCountryCode] = useState('US')
  return (
    <RegionContext.Provider value={{ region, countryCode, setRegion, setCountryCode }}>
      <ThemeProvider theme={THEMES_BY_REGION[region]}>{children}</ThemeProvider>
    </RegionContext.Provider>
  );
};

export const useRegion = () => useContext(RegionContext);
export default RegionProvider;
