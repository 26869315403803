export const rootFontSize = 16;
/**
 * Converts pixels to a rem value.
 * @example pxToRem(32) // '2rem'
 * pxToRem('32') // '2rem'
 * pxToRem('32px') // '2rem'
 */
export const pxToRem = (px: number | string): string =>
  `${(Number(px.toString().replace("px", "")) / rootFontSize).toFixed(2)}rem`;

const usColor = "#C81414";
const usColor2 = "#A10A0A";
const euColor = "#003C82";
const euColor2 = "#02245D";

const primary1 = "#00205b";
const primary2 = "#001123";
const primary3 = "#AF9157";

const accent1 = "#CF4520";
const accent2 = "#0077CB";
const accent3 = "#FFBE00";

const neutral0 = "#000000";
const neutral1 = "#FFFFFF";
const neutral2 = "#F4F4F4";
const neutral3 = "#D8D8D8";
const neutral4 = "#9E9E9E";
const neutral5 = "#6A6A6A";
const neutral6 = "#979797";
const neutral7 = "#F7F7F7";

// OLD
const primaryColor1 = "#C81414";
const primaryColor2 = "#990000";
const primaryColor3 = "#d0d3d4";
const primaryColor4 = "#797979";
const primaryColor5 = "#E0E0E0";
const primaryColor6 = "#f4f4f4";
const supportingColor1 = "#313131";
const supportingColor2 = "#151515";
const supportingColor3 = "#474747";
const supportingColor4 = "#004EAB";
const supportingColor5 = "#E4E4E4";
const supportingColor6 = "#D2451E";
const supportingColor7 = "#DE1717";
const supportingColor8 = "#868686";
const supportingColor9 = "#0D0D0D";
const lightGray = "#f3f3f3";
const altLightGray = "#f0f2f3";
const headerLogoColor = primaryColor1;
const headerBgColor = "#ffffff";
const headerBgBottomColor = primaryColor1;
const headerTextColor = "#000000";
const footerBgColor = primaryColor1;
const footerTextColor = "#ffffff";
const siteBgColor = "#ffffff";
const iframeSponserBgColor = "#006039";
const buttonColor = primaryColor1;
const buttonHoverColor = primaryColor2;
const buttonTextColor = "#ffffff";
const hyperlinkTextColor = primaryColor1;
// END OLD

export const palette = {
  primary1,
  primary2,
  primary3,
  accent1,
  accent2,
  accent3,
  neutral0,
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  neutral6,
  neutral7,

  //OLD
  primaryColor1,
  primaryColor2,
  primaryColor3,
  primaryColor4,
  primaryColor5,
  primaryColor6,
  supportingColor1,
  supportingColor2,
  supportingColor3,
  supportingColor4,
  supportingColor5,
  supportingColor6,
  supportingColor7,
  supportingColor8,
  supportingColor9,
  headerLogoColor,
  headerBgColor,
  headerBgBottomColor,
  headerTextColor,
  footerBgColor,
  footerTextColor,
  siteBgColor,
  iframeSponserBgColor,
  buttonColor,
  buttonHoverColor,
  buttonTextColor,
  hyperlinkTextColor,
  altLightGray,
  lightGray,
  // END OLD
};

interface PGATheme {
  primary: string;
  primary2: string;
}

export const UnitedStatesTheme: PGATheme = {
  primary: usColor,
  primary2: usColor2,
};

export const EuropeanTheme: PGATheme = {
  primary: euColor,
  primary2: euColor2,
};

export const maxWidth = "970px";
export const maxPageWidth = "701px";

const NUNITO = "Nunito Sans, Arial, Helvetica, sans-serif";
const ROBOTO = "Roboto Condensed, Arial, Helvetica, sans-serif";
const SERIF = "Source Serif Pro, Arial, Helvetica, sans-serif";

// OLD
const MAIN_REG = "Nunito Sans,Arial,Helvetica,sans-serif";
const MAIN_LIGHT = "Nunito Sans,Arial,Helvetica,sans-serif";
const MAIN_BOLD = "Nunito Sans,Arial,Helvetica,sans-serif";
const SECONDARY_BOLD = "Nunito Sans,Arial,Helvetica,sans-serif;";
// END OLD

export const FONTS = {
  NUNITO,
  SERIF,
  ROBOTO,

  // OLD
  MAIN_REG,
  MAIN_BOLD,
  MAIN_LIGHT,
  SECONDARY_BOLD,
  // END OLD
};

export const breakpoints = {
  mobile: "640px",
  tablet: "768px",
  laptop: "1024px",
  large: "1440px",
};

/**
 * Applies alpha to a given hex value.
 * @arg alpha default: 1
 * @example hexRGBA('#335596', 0.25); // rgba(51, 85, 150, 0.25)
 * hexRGBA('#eee', 0.25); // rgba(238, 238, 238, 0.25)
 */
export function hexRGBA(hex: string, alpha = 1): string {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    console.error(`Bad Hex Value: hexRGBA(${hex})`);
    return hex;
  }

  let c;
  c = hex.substring(1).split("");
  if (c.length == 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  }
  c = `0x${c.join("")}`;

  return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(", ")}, ${alpha})`;
}
