export const QUERIES = {
  GAME: "game",
  ROSTER: "roster",
  BRACKET: "bracket",
  GROUPDETAILS: "group",
  GROUPLIST: "group-list",
  GROUPS: "groups",
  LEADERBOARD: "leaderboard",
  SESSION: "session",
  ENTRY: "entry",
};