import { GlobalStyles } from "../styles/globalStyles";
import Head from "next/head";
import { useEffect, useRef } from "react";
import type { AppProps } from "next/app";
import PageLayout from "@components/PageLayout";
import { ClientContextProvider } from "../hooks/useClientContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { SessionProvider } from "../hooks/useSession";
import { polyfill } from "smoothscroll-polyfill";
import { useServeBundle } from "../hooks/useServeBundle";
import { AnalyticsScript } from "../hooks/useAnalytics";

function App({ Component, pageProps, err }: AppProps & { err: Error }): JSX.Element {
  const queryClientRef = useRef<any>(null);
  const { getWindowHash } = useServeBundle();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  useEffect(() => {
    polyfill();
    if (document.readyState === "complete") {
      getWindowHash();
    } else {
      window.addEventListener("load", getWindowHash);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <title>PGA Championship Fantasy</title>
      </Head>
      <GlobalStyles />
      <QueryClientProvider client={queryClientRef.current}>
        <ClientContextProvider>
          <SessionProvider>
            <PageLayout>
              <Component {...pageProps} err={err} />
            </PageLayout>
          </SessionProvider>
        </ClientContextProvider>
      </QueryClientProvider>
      <AnalyticsScript />
    </>
  );
}

export default App;
